@import '../../styles/variables.scss';
@import '../../styles/common.scss';

.security {
    position: relative;
    margin: 50px auto;
    max-width: 1300px;
    height: max-content;
    padding-top: 200px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
    .center {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;

        &_text {
            @extend .textbase;
        }
    
        &_subtext {
            @extend .subtextbase;
        }
    }
}

.under {
    background: $color-bg-variant;
    margin-top: 150px;

    &__section {
        @extend .sectionbase;
        height: 120vh;
        padding-top: 120px;
        flex-wrap: nowrap;
        overflow: hidden;

        &_text {
            @extend .textbase;
        }
    }

    &__sectionfaq {
        @extend .under__section;
        width: 100vw;

        @include breakpoint(sd) {
            height: max-content;
        }
    }
}

.faq {
    margin: 150px 0px 0px 0px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    height: max-content;

    @include breakpoint(hd) {
        margin: 50px 0px 0px 0px;
    }

    &::after{
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        border-left: 1px solid #fff;
        left: 50%;
    }

    @include breakpoint(sd) {
        margin: 20px auto;
        grid-template-columns: repeat(1, 1fr);

        &::after{
            border-left: none;
        }
    }
}

.containerfaq {
    display: flex;
    position: relative;
    height: max-content;
    overflow: hidden;
}

.down {
    background: #000;

    &__section {
        @extend .sectionbase;
        max-width: 1560px;
        height: 300vh;
        padding-top: 120px;
        padding-left: 20px;
        overflow: hidden;

        @include breakpoint(hd) {
            padding-left: 0px;
        }

        &_text {
            @extend .textbase;
            max-width: 100vw;
        }
    }

    &__footer {
        margin: 50px 30px 0px 30px;
        display: flex;
        justify-content: space-between;

        @include breakpoint(smd) {
            flex-direction: column;
            align-items: center;
            gap: 60px;
        }
    }

    &__button {
        margin-left: 30px;
        width: max-content;
        border: none;
        background: none;
        color: #fff;
        cursor: pointer;
        font-size: 19px;
        text-transform: uppercase;
        letter-spacing: 5px;
    }
}