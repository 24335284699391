@import '../../styles/variables.scss';
@import '../../styles/app.module.scss';

.header {
    position: relative;
    width: 100%;
    max-width: 1560px;
    margin: 0 auto;
    height: 50px;
    padding: 30px 40px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    &__title {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        color: $color-light;
        font-size: $font-size-big;
        font-weight: 400;
        text-transform: uppercase;
    }

    &__nav {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        z-index: 11;
        &_bsmall {
            width: 50px;
            height: 10px;
            cursor: pointer;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            border-left: none;
            border-right: none;
            background: none;
            transition: height 100ms ease-in-out;
        
            &:hover {
                height: 20px;
            }
        }
    }
}

.pos {
    position: fixed;
    display: flex;
    width: 100vw;
    margin: 0;
    z-index: 10;

    &::before{
        content: '';
        position: absolute;
        height: 0vh;
        top: -10px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -4;
        background: #000;
        border-bottom: 10px solid #fff;

        transition: height 400ms ease-in-out;
    }

    &.open {
        &::before{
            height: 100vh;
            transition: height 400ms ease-in-out;
        }
    }
}

.menu {
    position: absolute;
    left: 0px;
    top: -100vh;
    width: 100%;
    height: 100vh;
    transition: top 500ms ease-in-out;
    overflow: hidden;

    &.open {
        top: 0vh;
        transition: top 500ms ease-in-out;
    }

    &__container {
        position: relative;
        height: inherit;
        padding: 100px 40px 0px 20px;
        width: 70%;
        overflow-y: auto;

        @include breakpoint(sd) {
            padding: 100px 10px 0px 10px;
            width: 100%;
        }

        &_text {
            width: max-content;
            font-weight: 500;
            font-size: 110px;
            color: transparent;
            cursor: pointer;
            height: 160px;
            -webkit-text-stroke: 1px #fff;
            background: linear-gradient(#fff 50%, transparent 50%);
            -webkit-background-clip: text;
            background-clip: text;
            background-position-y: -47px;
            transition: background-position-y 300ms ease-in-out;

            &:hover {
                background-position-y: 32px;
                -webkit-background-clip: text;
                background-clip: text;
            }

            @include breakpoint(sd) {
                font-size: 40px;
                height: 90px;
            }
        }
    }
}

.scalebutton {
    @extend .button;
    height: 85vh;
    top: 40vh;
    transition: all 800ms ease-in-out;
}

hr {
    background: #565656;
    height: 1px;
    border: none;
    margin-bottom: 20px;
}