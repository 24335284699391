@import '../../styles/variables.scss';

.img {
    width: 12%;
    height: 21%;
    filter: saturate(0%);
    margin-top: 8%;

    @include breakpoint(hd) {
        width: 100px;
        height: 100px;
        filter: saturate(0%);
    }
}

.backed {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10%;
    margin-top: 50px;


    @include breakpoint(hd) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20%;
        margin: 20%;
        margin-top: 50px;
    }
    

    @include breakpoint(md) {
        margin: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
    }


    @include breakpoint(md) {
        margin: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
    }

    @include breakpoint(sd) {
        margin: 20px auto;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
    }
}