@import './variables.scss';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    font-family: $chakra;
}

::-webkit-scrollbar{
    display: none;
}

#root {
    height: 100%;
}

h6 {
    color: #727272;
    font-size: 18px;
    letter-spacing: 8px;
    font-weight: 500;
    text-transform: uppercase;
}