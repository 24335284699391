@import '../styles/variables.scss';

.main {
    position: relative;
    width: 100%;
    height: 100vh;
    background: $color-bg;
    display: flex;

    &__section {
        padding: 20px 35px;
        display: grid;
        grid-template-columns: 23% 75%;
        gap: 2%;
        overflow: hidden;
        height: 90%;
    }
}

@mixin button-color {
    margin: 100px 0px 150px 0px;

    &::before {
        background-color: #fff;
    }

    &::after{
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
}

.button {
    position: relative;
    top: 0vh;
    width: 350px;
    height: 60px;
    cursor: pointer;
    border-radius: 2px;
    border: none;
    background: none;
    color: $color-text;
    letter-spacing: 7px;
    transition: all 300ms ease-in-out;

    @include breakpoint(smd) {
        display: none;
    }

    &::before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: $color-primary;

        transition: transform 300ms ease-in-out;
        transform: scaleY(1);
    }

    &:hover::before {
        transform: scaleY(0);
    }

    &::after{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border-top: 2px solid $color-primary;
        border-bottom: 2px solid $color-primary;

        transition: transform 300ms ease-in-out;
        transform: scaleY(0);
    }

    &:hover::after {
        transform: scaleY(1);
    }
}

.buttonvariant {
    @include button-color();

    @include breakpoint(smd) {
        display: block;
    }
}