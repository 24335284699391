@import '../../styles/variables.scss';
@import '../../styles/common.scss';

.counter {
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 22px;
    letter-spacing: 2px;
    margin-top: 100px;
    margin-bottom: -100px;

    &__progress{
        position: absolute;
        top: 140px;
        width: 70px;
        height: 12px;
        accent-color: #fff;
        transition: all 300ms ease-in-out; 
    }
}

.main {
    margin-top: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background: none;
    z-index: 5;

    @include breakpoint(sd) {
        margin-top: 0px;
    }

    &__section {
        @extend .sectionbase;
        padding-top: 120px;
        max-width: 1200px;
        
        .center {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;

            &_text {
                @extend .textbase;
            }
        
            &_subtext {
                @extend .subtextbase;
            }
        }

        .left {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 80vw;
            height: 100%;
            justify-content: flex-start;
            align-content: flex-start;

            @include breakpoint(sd) {
                flex-direction: row;
            }

            &_text {
                @extend .textbase;
                text-align: left;
                max-width: 600px;

                @include breakpoint(ld) {
                    max-width: 400px;
                }
            }

            &_subtext {
                @extend .subtextbase;
                text-align: left;
                max-width: 400px;
            }
        }

        .right {
            margin-left: 70%;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            height: 100%;
            justify-content: flex-start;
            align-content: flex-end;

            @include breakpoint(smd) {
                margin: auto;
                width: 80vw;
            }

            @include breakpoint(sd) {
                flex-direction: row;
                align-content: flex-start;
            }

            &_text {
                @extend .textbase;
                text-align: left;
                max-width: 500px;
            }

            &_subtext {
                @extend .subtextbase;
                text-align: left;
                max-width: 400px;
            }
        }
    }

    &__sectionhorizontal {
        position: relative;
        width: 100vw;
        height: 500px;

        .scrollcontainer {
            width: 200vw;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            justify-items: center;
            align-content: center;

            &__hcell {
                display: flex;
                width: 1%;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;

                &_texthorizontal {
                    @extend .textbase;

                    @include breakpoint(sd) {
                        font-size: 19px;
                    }
                }
    
                &_subtexthorizontal {
                    @extend .subtextbase;
                    width: 350px;
                    justify-content: center;
                    align-content: center;

                    @include breakpoint(sd) {
                        font-size: 14px;
                        max-width: 120px;
                    }
                }
            }
        }
    }
}