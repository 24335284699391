.sectionbase {
    position: relative;
    margin: auto;
    max-width: 1300px;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.textbase {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    max-width: 1300px;
    color: $color-light;
    font-size: $font-size-extra;
    font-weight: $font-weight-large;
    line-height: 110px;
    text-transform: uppercase;

    @include breakpoint(ld) {
        font-size: 74px;
        font-weight: $font-weight-large;
        line-height: 74px;
        max-width: 900px;
    }

    @include breakpoint(md) {
        font-size: 54px;
        font-weight: $font-weight-big;
        line-height: 54px;
        max-width: 700px;
    }

    @include breakpoint(smd) {
        max-width: 400px;
    }

    @include breakpoint(sd) {
        font-size: 45px;
        font-weight: $font-weight-big;
        line-height: 45px;
    }
}

.subtextbase {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    max-width: 650px;
    color: $color-light;
    margin-top: 20px;
    font-size: 22px;
    font-weight: $font-weight-big;

    @include breakpoint(md) {
        max-width: 450px;
    }

    @include breakpoint(sd) {
        max-width: 200px;
    }
}