@import '../../styles/variables.scss';

.dropdown {
    margin: 0px;
    padding: 60px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    cursor: pointer;

    max-height: 150px;
    overflow: hidden;
    transition:  max-height 300ms ease-in-out;

    @include breakpoint(hd) {
        padding: 40px;
        max-height: 100px;
    }

    &__title {
        display: flex;
        justify-content: space-between;
    }

    h4 {
        color: #fff;
        margin-bottom: 70px;
        font-size: 22px;

        @include breakpoint(hd) {
            font-size: 18px;
            margin-bottom: 50px;
        }
    }

    img {
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
        transition: transform 300ms ease-in-out;
    }

    p {
        color: #fff;
        font-size: 16px;

        @include breakpoint(smd) {
            font-size: 13px;
        }
    }

    &.open {
        max-height: 1000px;
        transition: max-height 300ms ease-in-out;

        img {
            transform: rotate(-90deg);
        }
    }
}

.column {
    display: flex;
    flex-direction: column;
}