@import '../../styles/variables.scss';

li {
    margin-top: 15px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    list-style: none;
    margin-left: -15px;
    width: max-content;
    cursor: pointer;
    transition: margin-left 100ms ease-in-out;

    &:hover {
        margin-left: 0px;
    }
}

.img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.footer {
    width: 63%;
    display: grid;
    grid-template-columns: 61% 40%;
    margin-bottom: 50px;

    &__container {
        overflow: hidden;
    }

    @include breakpoint(sd) {
        width: 100%;
    }
}