@import '../../styles/variables.scss';

.contacts {
    display: flex;
    z-index: 20;
    padding: 70px 0px 50px 20px;

    @include breakpoint(smd) {
        padding: 0px;
        max-width: 350px;
        margin: 0 auto;
    }

    &__form {
        width: 100%;
        display: grid;
        grid-template-columns: 36% 38.3% 35%;

        @include breakpoint(smd) {
            display: flex;
            flex-direction: column;
        }

        &_input {
            margin-top: 30px;
            margin-right: 30px;
            width: 90%;
            height: 60px;
            color: #fff;
            background: none;
            border: none;
            border-bottom: 1px solid #fff;
            font-size: 30px;

            @include breakpoint(smd) {
                margin: 10;
                width: 100%;
            }
        }
    }
}