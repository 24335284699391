@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');

$chakra: 'Chakra Petch', sans-serif;

$color-bg: #0f1712;
$color-bg-variant: #03211a;
$color-primary: #1d483e;
$color-primary-variant: rgb(20, 149, 192);
$color-text: #2dc8a6;
$color-light: #ffffff;

$image-bg: radial-gradient(
        farthest-corner at 100% 40px,
        rgb(255, 255, 255) 10%,
        rgb(0, 175, 202) 50%
    );

$font-size-extra: 110px;
$font-size-big: 28px;
$font-size-medium: 16px;
$font-size-small: 12px;

$font-weight-big: 600;
$font-weight-large: 400;
$font-weight-medium: 300;
$font-weight-small: 200;

@mixin breakpoint($point) {

    @if $point == hd {
        @media (max-width: 1930px) {
            @content;
        }
    }

    @if $point == ld {
        @media (max-width: 1100px) {
            @content;
        }
    }

    @if $point == md {
        @media (max-width: 800px) {
            @content;
        }
    }

    @if $point == smd {
        @media (max-width: 650px) {
            @content;
        }
    }

    @if $point == sd {
        @media (max-width: 550px) {
            @content;
        }
    }
}